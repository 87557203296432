import Digital from "../img/DigitalTransformationAdvocacy.png";
import Advisory from "../img/Advisory&Consulting.png";
import Data from "../img/MarketData.png";
import Analytic from "../img/MarketAnalytics.png";
import Financial from "../img/FinancialCharting.png";
import Screener from "../img/Screener.png";
import Radar from "../img/MarketRadar.png";
import Coporate from "../img/CorporateActions.png";
import Portfolio from "../img/PortfolioServices.png";
import Robo from "../img/Robo-advisor.png";
import Api from "../img/APIandDataIntegration.png";

import stock from "../img/ICONStockbrokers.png";
import light from "../img/Lighthouse.png";
import mega from "../img/megaa.png";
import pivot from "../img/pivott.png";
import resort from "../img/Resort.png";
import rgt from "../img/royall.png";
import tiddo from "../img/Tiddo.png";
import veritas from "../img/Veritas.png";

export const abouts = [
  {
    id: 0,
    title: "Advisory and Consulting Services",
    description:
      "Are you looking for personalized guidance and support on topics, including strategic roadmaps, business and product development? We leverage industry knowledge and expertise to provide effective answers.",
    img: Advisory,
  },

  {
    id: 1,
    title: "Digital Transformation Advocacy",
    description:
      "We work with regulators and financial institutions to understand their ecosystem goals and challenges and provide guidance and support as they embark on their digital transformation journey.",
    img: Digital,
  },
];

export const services = [
  {
    id: 0,
    title: "Market Data",
    description:
      "Real-time insights for informed decisions. Access the latest market data and trends in real-time.",
    img: Data,
  },
  {
    id: 1,
    title: "Market Analytics",
    description:
      "Empowering intelligent decisions through advanced analytics. Analyze data with powerful tools to gain insights and make informed decisions.",
    img: Analytic,
  },
  {
    id: 2,
    title: "Financial Charting",
    description:
      "See the bigger picture with powerful financial charting tools. Make data-driven decisions with visually stunning financial charts.",
    img: Financial,
  },

  {
    id: 3,
    title: "Portfolio Services",
    description:
      "Stay in control of your investments with real-time monitoring. Keep track of your investments with comprehensive portfolio monitoring.",
    img: Portfolio,
  },
  {
    id: 4,
    title: "Market Radar",
    description:
      "Take control of your trades with real-time order book data. Get an inside look at market activity with real-time order book tracking.",
    img: Radar,
  },

  {
    id: 5,
    title: "Robo-Advisor",
    description:
      "Invest smarter and more efficiently with our advanced robo advisor. Trust in the power of technology to help you maximize your investment potential.",
    img: Robo,
  },

  {
    id: 6,
    title: "Screener",
    description:
      "Find your next investment opportunity with ease. Quickly find stocks that meet your criteria with advanced screening tools.",
    img: Screener,
  },
  {
    id: 7,
    title: "API and Data Integration",
    description:
      "Seamlessly integrate data to power your investment decisions. Streamline your investment process with easy API and data integrations.",
    img: Api,
  },
  {
    id: 8,
    title: "Corporate Actions",
    description:
      "Stay on top of corporate actions and make informed decisions. Gain valuable insights into potential investment opportunities with up-to-date corporate action information.",
    img: Coporate,
  },

];

export const customers = [
  {
    id: 0,
    img: light,
  },
  {
    id: 1,
    img: stock,
  },

  {
    id: 2,
    img: pivot,
  },

  {
    id: 3,
    img: resort,
  },

  {
    id: 4,
    img: rgt,
  },

  {
    id: 5,
    img: mega,
  },
  {
    id: 6,
    img: tiddo,
  },

  {
    id: 7,
    img: veritas,
  },
];

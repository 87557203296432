import React from 'react'
import { Link  } from "react-scroll";



const MobileMenu = ({handleClick}) => {
 
  const handleSetActive = (to) =>{
    console.log(to);
  }
  
  return (
    <div  className={`mobile-menu block lg:hidden  absolute top-[80px] left-0  bg-white shadow-md py-6  w-full transition ease-in duration-300 ` }>
         <div className="Links flex flex-col text-center text-[24px] space-y-4 font-bold text-primary">
         <Link
            activeClass="active" to="home" spy={true} smooth={true} offset={-100} duration={500} 
            onSetActive={handleSetActive}
            className="cursor-pointer hover:text-primary"
            onClick={handleClick}
            >
              <h6>Home</h6>
            </Link>

            <Link
                  spy={true} smooth={true} offset={-70} duration={500}
                  to="products"
                  className="cursor-pointer hover:text-primary"
                  onClick={handleClick}
            >
              <h6>Products</h6>
            </Link>

            <Link
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
              to="services"
              className="cursor-pointer hover:text-primary"
              onClick={handleClick}
            >
              <h6>Services</h6>
            </Link>


            <Link
                  spy={true} smooth={true} offset={-100} duration={500}
                  to="customer"
                  className="cursor-pointer hover:text-primary"
                  onClick={handleClick}
             
            >
              <h6>Our Customers</h6>
            </Link>

            <Link
                  spy={true} smooth={true} offset={-100} duration={500}
                  to="pricing"
                  className="cursor-pointer hover:text-primary"
                  onClick={handleClick}
            >
              <h6>Pricing</h6>
            </Link>

          

            <Link
                  spy={true} smooth={true} offset={-100} duration={500}
                  to="contact"
                  className="cursor-pointer hover:text-primary"
                  onClick={handleClick}
             
            >
              <h6>Contact Us</h6>
            </Link>
          </div>
      </div>
  )
}

export default MobileMenu
import {
    createBrowserRouter,
    RouterProvider,
    Outlet
  } from "react-router-dom"; 
  
  import { Header, FooterTest} from './components'
  import {Home, NotFound} from './pages'
  
  
  const Layout = () => {
    return (
      <>
      <Header />
      <Outlet />
      <FooterTest/>
      </>
    )
  }
  
  
  const router = createBrowserRouter([
    {
      path: '/',
      element: <Layout/>,
      children: [
        {
          path: '/',
          element: <Home />
        },
       
      ]
    },
    {
      path: '*',
      element: <NotFound />
    }
  
  ]);
  
  function App(){
    return(
      <RouterProvider router={router}/>
    )
  }
  
  export default App
import React from 'react'

import {services} from "../utils/constants"
import "../index.css"


import {DemoForm, PartnerForm} from '../components'




const About = () => {

  const imgSize = (i) => {
    if (i === 0 ){
      return 'w-28'
    }
    if (i === 2 ){
      return 'w-36'
    }
    if (i === 8 ){
      return 'w-48'
    }
    
    else{
      return 'w-32 lg:w-40'
    }
  }


  return (
    <>
    <section id="products" className="bg-[#F8FAFC] pt-20 lg:pt-32  space-y-32">

      <div className=" px-8 md:px-16 space-y-20">
      <div className="w-full ">
      <div className="w-full text-center space-y-6">
      <h1 className="font-bold tracking-wide text-[35px] leading-[32px] md:text-[45px] md:leading-[40px]">
      Innovative Tools and Services
      </h1>
      <p className="m-auto tracking-wide lg:px-24 text-[18px] leading-[32px] md:text-[28px] md:leading-[35px]">With our robust suite of data and analytics tools, you can introduce incremental innovation to your business by simply embedding top-notch finance applications into your workflows.</p>
      </div>
    </div>

    <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
      {
        services.map((service, i) => (
          <div key={i} className="flex text-left items-start lg:flex-row lg:items-start gap-4">
            <div>
              <img src={service.img} alt="" className={imgSize(i)} />
            </div>

            <div className="space-y-2 ">
              <h1 className="font-bold tracking-wide text-[26px] lg:text-[24px]">{service.title}</h1>
              <p className="text-[18px] leading-[28px]">{service.description}</p>

            </div>
          </div>
        ))
      }
      
    </div>
      </div>


    <div className="bg-[#03111A] px-8 md:px-16 py-24 text-center text-white space-y-20" >
      <h1 className="text-[36px] leading-[36px] md:text-[40px] md:leading-[40px] tracking-wide">Request a Callback For:</h1>

      <div className="flex items-center gap-20 lg:gap-32 lg:flex-row flex-col justify-center">
       

          <DemoForm/>

         <PartnerForm/>

      </div>

    
    </div>

   
   

   
  </section>

 

     
   

  </>
  )
}

export default About
import React, { useEffect } from "react";
import demo from "../img/Demo.png";

import "../index.css";

const DemoForm = () => {
  useEffect(() => {
    const importTE = async () => {
      await import("tw-elements");
    };
    importTE();
  }, []);

  return (
    <>
      <button
        type="button"
        className="flex flex-col lg:flex-row items-center gap-6"
        data-te-toggle="modal"
        data-te-target="#demoLg"
        data-te-ripple-init
      >
        <img src={demo} alt="" className="w-20 lg:w-18" />
        <h1 className="text-[30px] tracking-wide lg:text-[28px] font-[600]">
          Demo
        </h1>
      </button>

      <div
        data-te-modal-init
        className="fixed top-0 left-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
        id="demoLg"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          data-te-modal-dialog-ref
          className="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px]"
        >
          <div className="min-[576px]:shadow-[0_0.5rem_1rem_rgba(#000, 0.15)] pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none ">
            <div class=" p-4 text-black" data-te-modal-body-ref>
              <form
                className="py-5 px-8 "
                action="https://form.flodesk.com/forms/642181f4edbd2187f1354355/submit"
                method="post"
                data-ff-el="form"
              >
                <h1 className="text-[25px] tracking-wide leading-[20px] font-[700] pb-4">
                  Request for Demo
                </h1>

                <h1 className="text-[16px] leading-[18px] tracking-wide pb-4">
                  Would you like to have a feel for our tools and services? Do
                  you want a test-drive to determine whether these services are
                  a right fit for your use case?
                </h1>

                <div className="space-y-4" data-ff-el="content">
                  <div className="space-y-4" data-ff-el="fields">
                    <div className="text-[16px] leading-[19.2px] flex items-start flex-col gap-2 ">
                      <label
                        for="ff-642181f4edbd2187f1354355-firstName"
                        className="text-[16px] font-[600]"
                      >
                        Your name
                      </label>
                      <input
                        id="ff-642181f4edbd2187f1354355-firstName"
                        className="w-full  border-[1.5px] border-gray-300 p-[16px] rounded-[4px] placeholder-[#B8C0CC] outline-none text-gray-700  font-[700]"
                        type="text"
                        maxlength="255"
                        name="firstName"
                        placeholder="Your name"
                        data-ff-tab="firstName::email"
                      />
                    </div>

                    <div className="text-[16px] leading-[19.2px] flex items-start flex-col gap-2">
                      <label
                        for="ff-642181f4edbd2187f1354355-email"
                        className="text-[16px] font-[600]"
                      >
                        Your email
                      </label>
                      <input
                        id="ff-642181f4edbd2187f1354355-email"
                        className="w-full  border-[1.5px] border-gray-300 p-[16px] rounded-[4px] placeholder-[#B8C0CC] outline-none text-gray-700  font-[700]"
                        type="text"
                        maxlength="255"
                        name="email"
                        placeholder="Your email"
                        data-ff-tab="email:firstName:option1"
                        required
                      />
                    </div>

                    <input
                      type="text"
                      maxlength="255"
                      name="confirm_email_address"
                      style={{ display: "none" }}
                    />
                  </div>

                  <div class="ff-642181f4edbd2187f1354355__preference">
                    <div class="ff-642181f4edbd2187f1354355__preference-title">
                      <h1 className="text-[24px] font-[600] text-left">
                        Which services would you like to schedule a demo for?
                      </h1>
                    </div>

                    <div className="space-y-2 pt-4">
                      <div className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          name="preferences"
                          value="option1"
                          data-ff-tab="option1:email:option2"
                        />

                        <span className="text-[16px] font-[600]">
                          Data Services
                        </span>
                      </div>

                      <div className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          name="preferences"
                          value="option2"
                          data-ff-tab="option2:option1:option3"
                        />

                        <span className="text-[16px] font-[600]">
                          Analytics Services
                        </span>
                      </div>

                      <div className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          name="preferences"
                          value="option3"
                          data-ff-tab="option3:option2:cfKPo9EoaG"
                        />

                        <span className="text-[16px] font-[600]">
                          Portfolio Services
                        </span>
                      </div>

                      <div className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          name="preferences"
                          value="cfKPo9EoaG"
                          data-ff-tab="cfKPo9EoaG:option3:tFcaUX8sYz"
                        />

                        <span className="text-[16px] font-[600]">
                          Robo-advisory Services
                        </span>
                      </div>

                      <div className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          name="preferences"
                          value="tFcaUX8sYz"
                          data-ff-tab="tFcaUX8sYz:cfKPo9EoaG:XQEKeGLAZf"
                        />

                        <span className="text-[16px] font-[600]">
                          Financial Charting{" "}
                        </span>
                      </div>

                      <div className="flex items-center gap-2">
                        <input
                          type="checkbox"
                          name="preferences"
                          value="XQEKeGLAZf"
                          data-ff-tab="XQEKeGLAZf:tFcaUX8sYz:submit"
                        />

                        <span className="text-[16px] font-[600]">
                          Market Radar / Depth of Market
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    class="ff-642181f4edbd2187f1354355__footer"
                    data-ff-el="footer"
                  >
                    <button
                      type="submit"
                      className="bg-primary hover:bg-[#1361A6] text-white rounded-[4px] py-[10px] px-[30px]"
                      data-ff-el="submit"
                      data-ff-tab="submit"
                    >
                      <span>Request</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div class="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
              <button
                type="button"
                className="rounded bg-red-500  px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out focus:outline-none focus:ring-0 "
                data-te-modal-dismiss
                data-te-ripple-init
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DemoForm;

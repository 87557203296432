import React from "react";
import "react-tabs/style/react-tabs.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay} from "swiper";

import {customers} from "../utils/constants"

const Customer = () => {
  return (
    <section id="customer" className="pt-20 lg:py-32 px-8 md:px-16 bg-[#F2FAFF]">
      <h3 className="font-bold tracking-wide text-[36px] text-primary leading-[40px] md:text-[40px] md:leading-[40px] text-center lg:pb-4">Our Clients</h3>
      <p className="font-bold tracking-wide text-[18px] leading-[40px] md:text-[30px] md:leading-[40px] text-center lg:pb-10">
      Trusted by Financial Brands since 2018</p>


<Swiper 
    spaceBetween={50}
    slidesPerView={4}
  
    loop={true}
    autoplay={{
      delay: 2500,
      disableOnInteraction: false,
    }}
    modules={[Navigation, Autoplay]}
    breakpoints={{
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },

      768: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 4,
        spaceBetween: 50,
      },
    }}

    >
      <></>

        {   customers.map((customer, i) =>(
          <SwiperSlide key={i}>
              <div  className=" gap-10 ">
                    <img src={customer.img} alt="" className='w-full'/>

    
                </div>
            

          </SwiperSlide>
               
            ))}
  

    </Swiper> 
      
    
    </section>
  );
};

export default Customer;

import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState
} from 'react-accessible-accordion';

import {Other, Analytics, Data, Corporate, Digital, Financial, Portfolio, Radar, Screener } from "../Pricing";

const Pricing = () => {
  return (
    <section
      id="pricing"
      className="pt-20 lg:py-32 px-8 md:px-16 overflow-y-hidden xl:overflow-visible transition duration-150"
    >
      <h1 className="text-center tracking-wide font-bold text-[36px] leading-[40px] md:text-[40px] md:leading-[40px] pb-10">
        Pricing
      </h1>
      <div className="contianer overflow-x-auto xl:overflow-visible">
        <div className="container-content min-w-[1000px] pr-6 md:pr-0 relative">
          <div className="grid grid-cols-4 pt-10 lg:sticky lg:top-20 backdrop-blur-sm bg-white/80 z-[1] border-b border-gray-200 xl:mx-0 lg:-mx-10">
            <div className="py-7">
              <h4 className="text-[28px] leading-[40px] font-bold">
                Products & Features
              </h4>
            </div>
            {/* first plan */}
            <div className="p-7 flex flex-col border-l border-gray-200 justify-around">
              <div className="spacey-4">
                <h4 className="text-[28px] leading-[33.6px]">Pro Plan</h4>
                <p className="text-[20px] leading-[24px]">Single</p>
              </div>

              <div className="pt-10">
                {/* <h1 className="text-[35px] leading[48px] text-[#0F4E85] font-bold">
                  {" "}
                  ₦1.2m{" "}
                  <span className="text-[24px] leading-[28.8px] text-black font-normal">
                    /year
                  </span>
                </h1> */}
                 <h1 className="text-[35px] leading[48px] text-[#0F4E85] font-bold">
                  {" "}
                  Request Quote
                </h1>
              </div>
            </div>
            {/* second plan */}
            <div className="p-7 flex flex-col border-l border-gray-200 justify-around">
              <div className="spacey-4">
                <h4 className="text-[28px] leading-[33.6px]">Pro+</h4>
                <p className="text-[20px] leading-[24px]">Up to 5</p>
              </div>

              <div className="pt-10">
                {/* <h1 className="text-[35px] leading[48px] text-[#0F4E85] font-bold">
                  {" "}
                  ₦3.6m{" "}
                  <span className="text-[24px] leading-[28.8px] text-black font-normal">
                    {" "}
                    /year
                  </span>
                </h1> */}
                 <h1 className="text-[35px] leading[48px] text-[#0F4E85] font-bold">
                  {" "}
                  Request Quote
                </h1>
              </div>
            </div>
            {/* Third plan */}
            <div className="p-7 flex flex-col border-l border-gray-200 justify-around">
              <div className="spacey-4">
                <h4 className="text-[28px] leading-[33.6px]">Enterprise</h4>
                <p className="text-[20px] leading-[24px]">5+ Users</p>
              </div>

              <div className="pt-10">
                <h1 className="text-[35px] leading[48px] text-[#0F4E85] font-bold">
                  {" "}
                  Request Quote
                </h1>
              </div>
            </div>
            {/* Third plan end*/}
          </div>

          <Accordion allowZeroExpanded preExpanded={['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h']}>
 
             <AccordionItem uuid="a" >
            <AccordionItemHeading>
                <AccordionItemButton className="bg-[#EFF6FD] px-3 rounded-sm flex items-center justify-between mb-4">
                <h6 className="py-3 mt-10 first:mt-0 xl:mx-0 lg:-mx-10 text-[24px] leading-[40px]">DATA</h6> 
                <AccordionItemState>
                  {({expanded}) => (expanded ?  (<i class="fa-solid fa-chevron-up"></i> ) : ( <i class="fa-solid fa-chevron-down"></i>))}
                </AccordionItemState>

                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
             <Data/>
            </AccordionItemPanel>
           </AccordionItem>

           <AccordionItem uuid="b">
            <AccordionItemHeading>
                <AccordionItemButton className="bg-[#EFF6FD] px-3 rounded-sm flex items-center justify-between  mb-4">
                <h6 className="py-3 mt-10 first:mt-0 xl:mx-0 lg:-mx-10 text-[24px] leading-[40px]">ANALYTICS</h6> 
                <AccordionItemState>
                  {({expanded}) => (expanded ?  (<i class="fa-solid fa-chevron-up"></i> ) : ( <i class="fa-solid fa-chevron-down"></i>))}
                </AccordionItemState>

                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
           <Analytics/>
            </AccordionItemPanel>
           </AccordionItem>

           <AccordionItem uuid="c">
            <AccordionItemHeading>
                <AccordionItemButton className="bg-[#EFF6FD] px-3 rounded-sm flex items-center justify-between  mb-4">
                <h6 className="py-3 mt-10 first:mt-0 xl:mx-0 lg:-mx-10 text-[24px] leading-[40px]">FINANCIAL CHARTING & TRADING TERMINAL</h6> 
                <AccordionItemState>
                  {({expanded}) => (expanded ?  (<i class="fa-solid fa-chevron-up"></i> ) : ( <i class="fa-solid fa-chevron-down"></i>))}
                </AccordionItemState>

                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
           <Financial/>
            </AccordionItemPanel>
           </AccordionItem>

           <AccordionItem uuid="d">
            <AccordionItemHeading>
                <AccordionItemButton className="bg-[#EFF6FD] px-3 rounded-sm flex items-center justify-between  mb-4">
                <h6 className="py-3 mt-10 first:mt-0 xl:mx-0 lg:-mx-10 text-[24px] leading-[40px]">SCREENER</h6> 
                <AccordionItemState>
                  {({expanded}) => (expanded ?  (<i class="fa-solid fa-chevron-up"></i> ) : ( <i class="fa-solid fa-chevron-down"></i>))}
                </AccordionItemState>

                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
           <Screener/>
            </AccordionItemPanel>
           </AccordionItem>

           <AccordionItem uuid="e">
            <AccordionItemHeading>
                <AccordionItemButton className="bg-[#EFF6FD] px-3 rounded-sm flex items-center justify-between  mb-4">
                <h6 className="py-3 mt-10 first:mt-0 xl:mx-0 lg:-mx-10 text-[24px] leading-[40px]">DIGITAL ADVISOR</h6> 
                <AccordionItemState>
                  {({expanded}) => (expanded ?  (<i class="fa-solid fa-chevron-up"></i> ) : ( <i class="fa-solid fa-chevron-down"></i>))}
                </AccordionItemState>

                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
           <Digital/>
            </AccordionItemPanel>
           </AccordionItem>

           <AccordionItem uuid="f">
            <AccordionItemHeading>
                <AccordionItemButton className="bg-[#EFF6FD] px-3 rounded-sm flex items-center justify-between  mb-4">
                <h6 className="py-3 mt-10 first:mt-0 xl:mx-0 lg:-mx-10 text-[24px] leading-[40px]">PORTFOLIO SERVICES</h6> 
                <AccordionItemState>
                  {({expanded}) => (expanded ?  (<i class="fa-solid fa-chevron-up"></i> ) : ( <i class="fa-solid fa-chevron-down"></i>))}
                </AccordionItemState>

                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
           <Portfolio/>
            </AccordionItemPanel>
           </AccordionItem>

           <AccordionItem uuid="g">
            <AccordionItemHeading>
                <AccordionItemButton className="bg-[#EFF6FD] px-3 rounded-sm flex items-center justify-between  mb-4">
                <h6 className="py-3 mt-10 first:mt-0 xl:mx-0 lg:-mx-10 text-[24px] leading-[40px]">CORPORATE ACTION</h6> 
                <AccordionItemState>
                  {({expanded}) => (expanded ?  (<i class="fa-solid fa-chevron-up"></i> ) : ( <i class="fa-solid fa-chevron-down"></i>))}
                </AccordionItemState>

                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
           <Corporate/>
            </AccordionItemPanel>
           </AccordionItem>


           <AccordionItem uuid="h">
            <AccordionItemHeading>
                <AccordionItemButton className="bg-[#EFF6FD] px-3 rounded-sm flex items-center justify-between  mb-4">
                <h6 className="py-3 mt-10 first:mt-0 xl:mx-0 lg:-mx-10 text-[24px] leading-[40px]">RADAR</h6> 
                <AccordionItemState>
                  {({expanded}) => (expanded ?  (<i class="fa-solid fa-chevron-up"></i> ) : ( <i class="fa-solid fa-chevron-down"></i>))}
                </AccordionItemState>

                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
           <Radar/>
            </AccordionItemPanel>
           </AccordionItem>

          


          </Accordion>
          
          <Other/>
        </div>
      </div>
    </section>
  );
};

export default Pricing;

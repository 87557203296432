import React from 'react'
import Check from "../img/check.svg";

const Data = () => {
  return (
    <div className="xl:mx-0 lg:-mx-10">
    <table className="relative w-full">
      <tbody className="divide-y divide-gray-100">
        <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
          <th
            scope="row"
            className="w-1/4 py-3 pr-4 font-normal text-left"
          >
            <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
              Daily market round-up
            </p>
          </th>

          <td className="px-7 relative w-1/4 py-0">
            <img className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0">
            <img className="m-auto"  src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img className="m-auto"  src={Check} alt="" srcset="" />
          </td>
        </tr>

        <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
          <th
            scope="row"
            className="w-1/4 py-3 pr-4 font-normal text-left"
          >
            <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
            Weekly market round-up                 
               </p>
          </th>

          <td className="px-7 relative w-1/4 py-0 ">
            <img className="m-auto"  src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img className="m-auto"  src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
        </tr>

        <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
          <th
            scope="row"
            className="w-1/4 py-3 pr-4 font-normal text-left"
          >
            <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
            Price list archive (10+ years)
            </p>
          </th>

          <td className="px-7 relative w-1/4 py-0 ">
            <img className="m-auto"  src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto"  src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img className="m-auto"  src={Check} alt="" srcset="" />
          </td>
        </tr>

        <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
          <th
            scope="row"
            className="w-1/4 py-3 pr-4 font-normal text-left"
          >
            <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
            Daily range analysis
            </p>
          </th>

          <td className="px-7 relative w-1/4 py-0 ">
            <img className="m-auto"  src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img className="m-auto"  src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img className="m-auto"  src={Check} alt="" srcset="" />
          </td>
        </tr>

        <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
          <th
            scope="row"
            className="w-1/4 py-3 pr-4 font-normal text-left"
          >
            <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
            Daily cumulative returns analysis
            </p>
          </th>

          <td className="px-7 relative w-1/4 py-0 ">
            <img className="m-auto"  src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img className="m-auto"  src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img className="m-auto"  src={Check} alt="" srcset="" />
          </td>
        </tr>

        <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
          <th
            scope="row"
            className="w-1/4 py-3 pr-4 font-normal text-left"
          >
            <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
            Daily market sector analysis
            </p>
          </th>

          <td className="px-7 relative w-1/4 py-0 ">
            <img className="m-auto"  src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img className="m-auto"  src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img className="m-auto"  src={Check} alt="" srcset="" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  )
}

export default Data
import React from 'react'
import Check from "../img/check.svg";

const Radar = () => {
  return (
    <div className="xl:mx-0 lg:-mx-10">
    <table className="relative w-full">
      <tbody className="divide-y divide-gray-100">
        
            <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
          <th
            scope="row"
            className="w-1/4 py-3 pr-4 font-normal text-left"
          >
            <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
            Advanced analytics and visualization tools
              </p>
          </th>

          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
        </tr>

        <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
          <th
            scope="row"
            className="w-1/4 py-3 pr-4 font-normal text-left"
          >
            <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
            In-depth view of market activity, including market trends and the actions of other market participants    
               </p>
          </th>

          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
        </tr>

        <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
          <th
            scope="row"
            className="w-1/4 py-3 pr-4 font-normal text-left"
          >
            <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
            Order book and market depth data
            </p>
          </th>

          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img className="m-auto" src={Check} alt="" srcset="" />
          </td>
        </tr>

        <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
          <th
            scope="row"
            className="w-1/4 py-3 pr-4 font-normal text-left"
          >
            <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
            Real-time market information and statistics
            </p>
          </th>

          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
        </tr>

        <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
          <th
            scope="row"
            className="w-1/4 py-3 pr-4 font-normal text-left"
          >
            <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
            Trade signals generator
            </p>
          </th>

          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
        </tr>

        <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
          <th
            scope="row"
            className="w-1/4 py-3 pr-4 font-normal text-left"
          >
            <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
            Advanced filtering and sorting capabilities
            </p>
          </th>

          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
        </tr>

       


      </tbody>
    </table>
  </div>
  )
}

export default Radar
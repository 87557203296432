import React from 'react'
import {Link} from 'react-router-dom'
import NotFoundImg from '../img/404.svg'

const NotFound = () => {
  return (
    <section
    id="pricing"
    className="h-[100vh] flex items-center flex-col justify-center py-10 lg:py-32 px-8 md:px-16 w-full transition duration-150"
  >
    <div className=" text-center">

<img className='w-[350px] pb-4' src={NotFoundImg} alt="PageNotFound" srcset="" />
    <h1 className="text-center font-medium text-[35px] lg:text-[70px]">Page Not Found</h1>

    <Link  to="/">
            <button className="text-[20px] tracking-wide leading-[19.2px] font-[600] bg-primary hover:bg-[#1361A6] text-white hover:text-white py-4 px-14 rounded-sm">
Home </button>
          </Link>
    </div>


    </section>
  )
}

export default NotFound
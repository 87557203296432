import React from 'react'
import Check from "../img/check.svg";

const Financial = () => {
  return (
    <div className="xl:mx-0 lg:-mx-10">
    <table className="relative w-full">
      <tbody className="divide-y divide-gray-100">
        <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
          <th
            scope="row"
            className="w-1/4 py-3 pr-4 font-normal text-left"
          >
            <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
            Advanced charting capabilities with a wide range of technical indicators and drawing tools               
              </p>
          </th>

          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
        </tr>

        <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
          <th
            scope="row"
            className="w-1/4 py-3 pr-4 font-normal text-left"
          >
            <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
            Real-time market  data and news            
               </p>
          </th>

          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
        </tr>

        <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
          <th
            scope="row"
            className="w-1/4 py-3 pr-4 font-normal text-left"
          >
            <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
            Direct trading and position management from charts
            </p>
          </th>

          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
        </tr>

        <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
          <th
            scope="row"
            className="w-1/4 py-3 pr-4 font-normal text-left"
          >
            <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
            Customizable watchlists and alerts
            </p>
          </th>

          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
        </tr>

        <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
          <th
            scope="row"
            className="w-1/4 py-3 pr-4 font-normal text-left"
          >
            <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
            Advanced order types and trade execution
            </p>
          </th>

          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
        </tr>

        <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
          <th
            scope="row"
            className="w-1/4 py-3 pr-4 font-normal text-left"
          >
            <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
            Access to multiple exchanges, symbols, and global markets
            </p>
          </th>

          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
        </tr>

        <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
          <th
            scope="row"
            className="w-1/4 py-3 pr-4 font-normal text-left"
          >
            <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
            Wide range of mobile and web-based platform for seamless integration
            </p>
          </th>

          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
        </tr>

      </tbody>
    </table>
  </div>
  )
}

export default Financial
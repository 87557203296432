import React from 'react'
import Check from "../img/check.svg";
import Cancel from "../img/cancel.svg";

const Other = () => {
  return (
    <div className="xl:mx-0 lg:-mx-10 pb-20 ">
    <table className="relative w-full">
      <tbody className="divide-y divide-gray-100">
        
            <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group border-t-[0.8px]">
          <th
            scope="row"
            className="w-1/4 py-3 pr-4 font-normal text-left"
          >
            <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
            API services
              </p>
          </th>

          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Cancel} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
        </tr>

        <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
          <th
            scope="row"
            className="w-1/4 py-3 pr-4 font-normal text-left"
          >
            <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
          White label services
               </p>
          </th>

          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Cancel} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
        </tr>

       

        <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
          <th
            scope="row"
            className="w-1/4 py-3 pr-4 font-normal text-left"
          >
            <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
            User licenses
            </p>
          </th>

          <td className="px-7 relative w-1/4 py-0 ">
          <p className="text-[18px] leading-[32px] text-center">
           1
            </p>
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
          <p className="text-[18px] leading-[32px] text-center">
           Up to 5
            </p>
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
          <p className="text-[18px] leading-[32px] text-center">
           More than 5
            </p>
          </td>
        </tr>

        <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
          <th
            scope="row"
            className="w-1/4 py-3 pr-4 font-normal text-left"
          >
            <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
            Self hosted services
            </p>
          </th>

          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Cancel} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Cancel} alt="" srcset="" />
          </td>
          <td className="px-7 relative w-1/4 py-0 ">
            <img  className="m-auto" src={Check} alt="" srcset="" />
          </td>
        </tr>

       
       

      </tbody>
    </table>
  </div>
  )
}

export default Other
import React from 'react'
import Check from "../img/check.svg";
const Analytics = () => {
  return (
    <div className="xl:mx-0 lg:-mx-10">
            <table className="relative w-full">
              <tbody className="divide-y divide-gray-100">
                <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
                  <th
                    scope="row"
                    className="w-1/4 py-3 pr-4 font-normal text-left"
                  >
                    <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
                    10+ guru portfolio models                  
                      </p>
                  </th>

                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                </tr>

                <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
                  <th
                    scope="row"
                    className="w-1/4 py-3 pr-4 font-normal text-left"
                  >
                    <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
                    Weekly dividend forecast               
                       </p>
                  </th>

                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                </tr>

                <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
                  <th
                    scope="row"
                    className="w-1/4 py-3 pr-4 font-normal text-left"
                  >
                    <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
                    Weekly stocks health analytsis
                    </p>
                  </th>

                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                </tr>

                <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
                  <th
                    scope="row"
                    className="w-1/4 py-3 pr-4 font-normal text-left"
                  >
                    <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
                    Weekly discount stocks recommendation
                    </p>
                  </th>

                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                </tr>

                <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
                  <th
                    scope="row"
                    className="w-1/4 py-3 pr-4 font-normal text-left"
                  >
                    <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
                    Weekly stocks fundamental analysis
                    </p>
                  </th>

                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                </tr>

                <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
                  <th
                    scope="row"
                    className="w-1/4 py-3 pr-4 font-normal text-left"
                  >
                    <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
                    Daily stocks technical analysis
                    </p>
                  </th>

                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                </tr>

                <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
                  <th
                    scope="row"
                    className="w-1/4 py-3 pr-4 font-normal text-left"
                  >
                    <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
                    Daily stock market trend locator
                    </p>
                  </th>

                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                </tr>

                <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
                  <th
                    scope="row"
                    className="w-1/4 py-3 pr-4 font-normal text-left"
                  >
                    <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
                    Daily stock market price-volume analysis
                    </p>
                  </th>

                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                </tr>

                <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
                  <th
                    scope="row"
                    className="w-1/4 py-3 pr-4 font-normal text-left"
                  >
                    <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
                    Daily stock market short-term outlook
                    </p>
                  </th>

                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                </tr>

                <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
                  <th
                    scope="row"
                    className="w-1/4 py-3 pr-4 font-normal text-left"
                  >
                    <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
                    Weekly stock market medium-term outlook
                    </p>
                  </th>

                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto"  src={Check} alt="" srcset="" />
                  </td>
                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto" src={Check} alt="" srcset="" />
                  </td>
                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto" src={Check} alt="" srcset="" />
                  </td>
                </tr>

                <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
                  <th
                    scope="row"
                    className="w-1/4 py-3 pr-4 font-normal text-left"
                  >
                    <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
                    Daily trade volume analysis
                    </p>
                  </th>

                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto" src={Check} alt="" srcset="" />
                  </td>
                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto" src={Check} alt="" srcset="" />
                  </td>
                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto" src={Check} alt="" srcset="" />
                  </td>
                </tr>

                <tr className="hover:bg-gray-200 hover:bg-opacity-20 divide-x group">
                  <th
                    scope="row"
                    className="w-1/4 py-3 pr-4 font-normal text-left"
                  >
                    <p className="text-[18px] leading-[32px] inline-block group-hover:translate-x-4 transition ease-in-out duration-100 border-b border-dashed border-gray-300 group-hover:border-gray-400">
                    Weekly stock ranking
                    </p>
                  </th>

                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto" src={Check} alt="" srcset="" />
                  </td>
                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto" src={Check} alt="" srcset="" />
                  </td>
                  <td className="px-7 relative w-1/4 py-0 ">
                    <img className="m-auto" src={Check} alt="" srcset="" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
  )
}

export default Analytics
import React from "react";
import { Link } from "react-scroll";
import HeroBanner from "../img/bg-hero.svg";
import Hero from "../img/Real-time monitoring solutions.png";
// import line from "../img/line.svg"
import "../index.css";

const HeroSection = () => {
  return (
    <section
      id="home"
      className=" -mt-[5.5rem] px-8 md:px-16 lg:py-20"
      style={{ backgroundImage: `url(${HeroBanner})`, backgroundSize: `cover` }}
    >
      <div className="grid grid-cols-1 lg:grid-cols-2 pt-36 gap-6 self-start">
        <div className="text-white lg:pr-10 self-center space-y-6 text-center lg:text-left">
          <h1 className="text-[35px] tracking-wide lg:text-[39px] lg:leading-[54px] font-bold">
          Capital Markets <span className="text-primary ">Data and Intel </span> 
          Made Easy, Affordable, and Accessible {" "}
            <span className="text-primary ">Anytime, Anywhere!</span>
          </h1>
          <p className="text-[24px] tracking-wide lg:text-[24px] leading-[30px] font-medium text-[#F7F8F9]">
          A web-based platform that empowers HNIs, Trading and Investment Professionals, and Institutions with Real-Time Market Data and Actionable Intelligence for Informed Decisions.
          </p>
            <div className="pb-10 lg:pb-0">
            <Link spy={true} smooth={true} duration={500} to="contact">
            <button className="text-[20px] tracking-wide leading-[19.2px] font-[600] bg-primary hover:bg-[#1361A6] text-white hover:text-white py-4 px-14 rounded-sm">
Let's talk about it! </button>
          </Link>
            </div>
        
        </div>


          <div className="hero-banner pb-20 lg:pb-0 ">
            <img src={Hero} alt="" className="m-auto w-[400px]" />
          </div>
       
      </div>
    
    </section>
  );
};

export default HeroSection;

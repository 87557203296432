import { React, useCallback, useState, useEffect } from "react";
import Logo from "../img/logo.svg";
import { Link } from "react-scroll";

import "../index.css";

import { MobileMenu } from "../components";

const Header = () => {
  const [active, setActive] = useState(false);

  const [scroll, setScroll] = useState(false);

  //check the navigation on scroll

  const isActive = () => {
    window.scrollY > 0 ? setScroll(true) : setScroll(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", isActive);

    return () => {
      window.removeEventListener("scroll", isActive);
    };
  }, []);

  const handleSetActive = (to) => {
    console.log(to);
  };

  const handleClick = useCallback(() => {
    setActive(false);
  }, []);

  return (
    <header
      className={`${
        scroll
          ? "bg-white fixed top-0 left-0 "
          : "text-white "
      }shadow-md px-8 lg:px-16 py-6 w-full z-10 transition-all ease`}
    >
      <nav className=" flex items-center gap-4 h-full relative">
        <div className="logo ">
          <a href="/"  onClick={handleClick}>
            <img
              className="w-[180px] lg:w-[198px] h-[32.4px] cursor-pointer"
              src={Logo}
              alt=""
            />
          </a>
        </div>

        <div className="flex-1 hidden lg:block">
          <div className="Links flex gap-10 justify-end items-center text-[20px] pt-2 font-[500]">
            <Link
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
              onSetActive={handleSetActive}
              className="cursor-pointer"
            >
              <h6>Home</h6>
            </Link>

            <Link
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
              to="products"
              className="cursor-pointer"
            >
              <h6>Products</h6>
            </Link>

            <Link
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
              to="services"
              className="cursor-pointer"
            >
              <h6>Services</h6>
            </Link>

            <Link
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
              to="customer"
              className="cursor-pointer"
            >
              <h6>Our Customers</h6>
            </Link>

            <Link
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
              to="pricing"
              className="cursor-pointer"
            >
              <h6>Pricing</h6>
            </Link>

            <Link
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
              to="contact"
              className="cursor-pointer"
            >
              <h6>Contact Us</h6>
            </Link>
          </div>
        </div>

        {/* handle mobile toggling */}
        <div className=" ml-auto self-center">
          <button onClick={() => setActive(!active)} className="flex lg:hidden">
            {active ? (
              <i class="fa-solid fa-xmark text-[30px]"></i>
            ) : (
              <i class="fa-solid fa-bars text-[30px]"></i>
            )}
          </button>
        </div>
        {/* handle mobile toggling End */}
      </nav>

      {/* mobile Menu */}
      {active && <MobileMenu handleClick={handleClick} />}
    </header>
  );
};

export default Header;

import React from 'react'
import { HeroSection, Products,Services, Pricing, Customer} from '../sections'

const Home = () => {
  return (
    <>
<HeroSection/>
<Products/>
<Services/>
<Customer/>
<Pricing/>


    </>
  )
}

export default Home
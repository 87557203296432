import React from "react";

import address from "../img/address.svg";
import call from "../img/call.svg";
import email from "../img/email.svg";
import twitter from "../img/twitter-icon.svg";
import linkedin from "../img/linkedin-icon.svg";

const FooterTest = () => {
  return (
    <>
    
      <section
        id="contact"
        className="py-20 px-8 md:px-16 bg-[#03111A] space-y-12"
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 lg:gap-5">
          <div className="lg:w-[60%] flex items-start flex-col">
            <div className="contact text-white mb-24">
              <h1 className="text-[32px] leading-[40px] font-[700] pb-4">
                Contact Us
              </h1>

              <div className="space-y-3">
                <div className="flex items-center gap-2">
                  <img src={email} alt="" className="w-[20px] h-[17px]" />
                  <p>info@nayafinance.com</p>
                </div>

                <div className="flex items-center gap-2">
                  <img src={address} alt="" className="w-[20px] h-[17px]" />
                  <p>
                    17th Floor, Western House, 8/10 Broad St, Lagos Island
                    102273, Lagos
                  </p>
                </div>

                <div className="flex items-center gap-2">
                  <img src={call} alt="" className="w-[20px] h-[17px]" />
                  <p>(+234)702-500-3524</p>
                </div>
              </div>
            </div>

            <div className="office-hour text-white">
              <h1 className="text-[24px] leading-[28.8px] font-[700] pb-4">
                Office Hours
              </h1>
              <div className="space-y-3">
                <p className="text-[20px] leading-[24px] font-[500]">
                  Monday to Friday
                </p>
                <p className="text-[20px] leading-[24px] font-[500]">
                  9:00 am to 4:00 pm
                </p>
                <p className="text-[20px] leading-[24px] font-[500]">
                  Closed on Saturdays and Sundays
                </p>
              </div>

              <div className="socials flex items-center gap-4 pt-6">
               <a href="https://twitter.com/nayafinance" target="_blank" rel="noopener noreferrer"> <img src={twitter} alt="" className="w-[24px] h-[24px]" /></a>
              <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/company/nayafinance/">
              <img src={linkedin} alt="" className="w-[24px] h-[24px]" />
              </a>
              </div>
            </div>
          </div> 

          <div className="form text-white">
            <h1 className="text-[24px] leading-[34px] font-[700] pb-4">
              Let's get this conversation started. Tell us a bit about yourself
              and we'll get in touch as soon as possible.
            </h1>
            <form
              className="space-y-4"
              action="https://form.flodesk.com/forms/6419922b8b2493e1ce9e4d0a/submit"
              method="post"
              data-ff-el="form"
            >
             
              <div
                class="ff-6419922b8b2493e1ce9e4d0a__content fd-form-content"
                data-ff-el="content"
                className="space-y-4"
              >
                <div
                  className="space-y-4"
                
                  data-ff-el="fields"
                >
                  <div
                    class="ff-6419922b8b2493e1ce9e4d0a__field fd-form-group"
                    className="text-[16px] leading-[19.2px] flex items-start flex-col gap-2"
                  >
                    <label
                      for="ff-6419922b8b2493e1ce9e4d0a-firstName"
        
                    >
                      Name
                    </label>
                    <input
                      id="ff-6419922b8b2493e1ce9e4d0a-firstName"
                      className="w-full p-[16px] rounded-[4px] placeholder-[#B8C0CC] outline-none text-gray-700  font-[700] "
                      // class="ff-6419922b8b2493e1ce9e4d0a__control fd-form-control"
                      type="text"
                      maxlength="255"
                      name="firstName"
                      placeholder="Name"
                      data-ff-tab="firstName::email"
                    />
                  </div>

                  <div
                 
                    className="text-[16px] leading-[19.2px] flex items-start flex-col gap-2"
                  >
                    <label for="ff-6419922b8b2493e1ce9e4d0a-email">
                      Work Email
                    </label>

                    <input
                      id="ff-6419922b8b2493e1ce9e4d0a-email"
                      className="w-full p-[16px] rounded-[4px] placeholder-[#B8C0CC] outline-none text-gray-700  font-[700] "
                      type="text"
                      maxlength="255"
                      name="email"
                      placeholder="Work Email"
                      data-ff-tab="email:firstName:fields.phone"
                      required
                    />
                  </div>

                  <div
                  
                    className="text-[16px] leading-[19.2px] flex items-start flex-col gap-2"
                  >
                    <label
                      for="ff-6419922b8b2493e1ce9e4d0a-Y9eLRAmGuI"
                      class="ff-6419922b8b2493e1ce9e4d0a__label fd-form-label"
                    >
                      Phone
                    </label>

                    <input
                      id="ff-6419922b8b2493e1ce9e4d0a-Y9eLRAmGuI"
                      className="w-full p-[16px] rounded-[4px] placeholder-[#B8C0CC] outline-none text-gray-700  font-[700]"
                      type="text"
                      maxlength="255"
                      name="fields.phone"
                      placeholder="Phone"
                      data-ff-tab="fields.phone:email:option2"
                    />
                  </div>

               
                </div>

                <div
                  class="ff-6419922b8b2493e1ce9e4d0a__preference"
                  className="space-y-2 pt-4"
                >
                  <div class="ff-6419922b8b2493e1ce9e4d0a__preference-title">
                    <div>
                      <h1 className="text-[24px]">What interests you?</h1>
                    </div>
                  </div>

                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      name="preferences"
                      class="fd-form-check__input"
                      value="option2"
                      data-ff-tab="option2:fields.phone:option1"
                    />

                    <span class="fd-form-check__label">Data Services</span>
                  </div>

                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      name="preferences"
                      class="fd-form-check__input"
                      value="option1"
                      data-ff-tab="option1:option2:option3"
                    />

                    <span class="fd-form-check__label">Analytics Services</span>
                  </div>

                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      name="preferences"
                      class="fd-form-check__input"
                      value="option3"
                      data-ff-tab="option3:option1:j2mYDCvD6m"
                    />

                    <span class="fd-form-check__label">Portfolio Services</span>
                  </div>

                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      name="preferences"
                      class="fd-form-check__input"
                      value="j2mYDCvD6m"
                      data-ff-tab="j2mYDCvD6m:option3:zlQ76zZ5C4"
                    />

                    <span class="fd-form-check__label">
                      Robo-Advisory Services
                    </span>
                  </div>

                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      name="preferences"
                      class="fd-form-check__input"
                      value="zlQ76zZ5C4"
                      data-ff-tab="zlQ76zZ5C4:j2mYDCvD6m:XCOsjAZXq4"
                    />

                    <span class="fd-form-check__label">Market Radar</span>
                  </div>

                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      name="preferences"
                      class="fd-form-check__input"
                      value="XCOsjAZXq4"
                      data-ff-tab="XCOsjAZXq4:zlQ76zZ5C4:submit"
                    />

                    <span class="fd-form-check__label">API Services</span>
                  </div>
                </div>

                <div
                  class="ff-6419922b8b2493e1ce9e4d0a__footer"
                  data-ff-el="footer"
                >
                  <button
                    type="submit"
                    class="ff-6419922b8b2493e1ce9e4d0a__button fd-btn"
                    data-ff-el="submit"
                    data-ff-tab="submit"
                    className="bg-primary hover:bg-[#1361A6] text-white rounded-[4px] py-[16px] px-[24px]"
                  >
                    <span>Submit & we'll be right back.</span>
                  </button>
                </div>
              </div>
            
              <div
                class="ff-6419922b8b2493e1ce9e4d0a__error fd-form-error"
                data-ff-el="error"
              ></div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default FooterTest;

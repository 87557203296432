import React from 'react';
import ReactDOM from 'react-dom/client';
import Modal from 'react-modal'
import './index.css';
import App from './App';
//import 'react-multi-carousel/lib/styles.css';
// import "swiper/css";
// import "swiper/css/navigation";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";

Modal.setAppElement('#root')
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
import React, {  useEffect } from "react";

import partnership from "../img/Partnership.png";
import "../index.css";


const PartnerForm = () => {

useEffect(() => {
    const importTE = async () => {
      await import("tw-elements");
    };
    importTE();
  }, []);


  return (
    <>
      {/* <button
        onClick={openModal}
        className="flex flex-col lg:flex-row items-center gap-6"
      >
        <img src={partnership} alt="" className="w-20 lg:w-18" />
        <h1 className="text-[30px] lg:text-[28px] font-[600]">Partnership</h1>
      </button> */}

      {/* <Modal
        closeTimeoutMS={300}
        isOpen={IsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <form
           className="py-5 px-8 "
          action="https://form.flodesk.com/forms/642188daedbd2187f135435b/submit"
          method="post"
          data-ff-el="form"
        >
                                 <h1 className="text-[25px] leading-[20px] font-[700] pb-4">
                                 Request for Partnership
                                 </h1>
            
             
                                 <h1 className="text-[16px] leading-[18px] pb-4">
                We believe that collaboration and partnership are crucial to the
                complete transformation of the industry. Do you have partnership
                or collaborative ideas you think would be a good fit?
           </h1>


          <div
           className="space-y-4"
            data-ff-el="content"
          >
            <div
              className="space-y-4"
              data-ff-el="fields"
            >
              <div class="ff-642188daedbd2187f135435b__field fd-form-group">
              <label
                  for="ff-642188daedbd2187f135435b-firstName"
                  className="text-[16px] font-[600]"
                >
                  Your name
                </label>
                <input
                  id="ff-642188daedbd2187f135435b-firstName"
                  className="w-full  border-[1.5px] border-gray-300 p-[12px] rounded-[4px] placeholder-[#B8C0CC] outline-none text-gray-700  font-[700]"
                  type="text"
                  maxlength="255"
                  name="firstName"
                  placeholder="Your name"
                  data-ff-tab="firstName::email"
                />
             
              </div>

              <div class="ff-642188daedbd2187f135435b__field fd-form-group">
              <label
                  for="ff-642188daedbd2187f135435b-email"
                  className="text-[16px] font-[600]"
                >
                  Your email
                </label>
                <input
                  id="ff-642188daedbd2187f135435b-email"
                  className="w-full  border-[1.5px] border-gray-300 p-[12px] rounded-[4px] placeholder-[#B8C0CC] outline-none text-gray-700  font-[700]"
                  type="text"
                  maxlength="255"
                  name="email"
                  placeholder="Your email"
                  data-ff-tab="email:firstName:option1"
                  required
                />
               
              </div>

            
            </div>

            <div class="ff-642188daedbd2187f135435b__preference">
              <div class="ff-642188daedbd2187f135435b__preference-title">
              <h1 className="text-[25px] font-[600]">
                      How would you like to partner with us?
                      </h1>
                 
              </div>


              <div  className="space-y-2 pt-4">
               
                  <div className="flex items-center gap-2">
                  <input
                        type="checkbox"
                        name="preferences"
                        value="option1"
                        data-ff-tab="option1:email:option2"
                      />
                  
                      <span  className="text-[16px] font-[600]">
                        Tools and Services
                      </span>
                    
                  </div>

                  <div className="flex items-center gap-2">
                  
                      <input
                        type="checkbox"
                        name="preferences"
                        class="fd-form-check__input"
                        value="option2"
                        data-ff-tab="option2:option1:option3"
                      />
                 
                      <span  className="text-[16px] font-[600]">
                        Digital Transformation Advocacy
                      </span>
                 
                  </div>

                  <div className="flex items-center gap-2">
                   
                      <input
                        type="checkbox"
                        name="preferences"
                        value="option3"
                        data-ff-tab="option3:option2:cfKPo9EoaG"
                      />
        
                      <span  className="text-[16px] font-[600]">
                        Advisory and Consulting Services
                      </span>
                 
                  </div>

                  <div className="flex items-center gap-2">
                 
                      <input
                        type="checkbox"
                        name="preferences"

                        value="cfKPo9EoaG"
                        data-ff-tab="cfKPo9EoaG:option3:submit"
                      />
                    
                      <span  className="text-[16px] font-[600]">Other Ideas</span>
                  
                  </div>
             
              </div>
            </div>
            <div
              class="ff-642188daedbd2187f135435b__footer"
              data-ff-el="footer"
            >
              <button
                type="submit"
                className="bg-primary hover:bg-[#1361A6] text-white rounded-[4px] py-[10px] px-[30px]"
                data-ff-el="submit"
                data-ff-tab="submit"
              >
                <span>Request</span>
              </button>
            </div>
          </div>
        </form>

        <div className="px-8 w-full flex">
                <button onClick={closeModal} className="ml-auto text-[20px] w-10 h-10 rounded-full bg-red-500 text-white" >x</button>
                </div>
      </Modal> */}


      <button
  type="button"
  className="flex flex-col lg:flex-row items-center gap-6"
  data-te-toggle="modal"
  data-te-target="#partner"
  data-te-ripple-init
  >
   <img src={partnership} alt="" className="w-20 lg:w-18" />
        <h1 className="text-[30px] tracking-wide lg:text-[28px] font-[600]">Partnership</h1>
 
</button>
      <div
  data-te-modal-init
  className="fixed top-0 left-0 z-[1055] hidden h-full w-full overflow-y-auto overflow-x-hidden outline-none"
  id="partner"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div
    data-te-modal-dialog-ref
    className="pointer-events-none relative w-auto translate-y-[-50px] opacity-0 transition-all duration-300 ease-in-out min-[576px]:mx-auto min-[576px]:mt-7 min-[576px]:max-w-[500px] min-[992px]:max-w-[800px]">
    <div
      className="min-[576px]:shadow-[0_0.5rem_1rem_rgba(#000, 0.15)] pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none ">
     


      <div class=" p-4 text-black" data-te-modal-body-ref>
      <form
           className="py-5 px-8 "
          action="https://form.flodesk.com/forms/642188daedbd2187f135435b/submit"
          method="post"
          data-ff-el="form"
        >
                                 <h1 className="text-[25px] tracking-wide leading-[24px] font-[700] pb-4">
                                 Request for Partnership
                                 </h1>
            
             
                                 <h1 className="text-[16px] tracking-wide leading-[18px] pb-4">
                We believe that collaboration and partnership are crucial to the
                complete transformation of the industry. Do you have partnership
                or collaborative ideas you think would be a good fit?
           </h1>


          <div
           className="space-y-4"
            data-ff-el="content"
          >
            <div
              className="space-y-4"
              data-ff-el="fields"
            >
              <div class="ff-642188daedbd2187f135435b__field fd-form-group text-left">
              <label
                  for="ff-642188daedbd2187f135435b-firstName"
                  className="text-[16px] font-[600] text-left"
                >
                  Your name
                </label>
                <input
                  id="ff-642188daedbd2187f135435b-firstName"
                  className="w-full  border-[1.5px] border-gray-300 p-[12px] rounded-[4px] placeholder-[#B8C0CC] outline-none text-gray-700  font-[700]"
                  type="text"
                  maxlength="255"
                  name="firstName"
                  placeholder="Your name"
                  data-ff-tab="firstName::email"
                />
             
              </div>

              <div class="ff-642188daedbd2187f135435b__field fd-form-group  text-left">
              <label
                  for="ff-642188daedbd2187f135435b-email"
                  className="text-[16px] font-[600]"
                >
                  Your email
                </label>
                <input
                  id="ff-642188daedbd2187f135435b-email"
                  className="w-full  border-[1.5px] border-gray-300 p-[12px] rounded-[4px] placeholder-[#B8C0CC] outline-none text-gray-700  font-[700]"
                  type="text"
                  maxlength="255"
                  name="email"
                  placeholder="Your email"
                  data-ff-tab="email:firstName:option1"
                  required
                />
               
              </div>

            
            </div>

            <div class="ff-642188daedbd2187f135435b__preference">
              <div class="ff-642188daedbd2187f135435b__preference-title">
              <h1 className="text-[25px] tracking-wide font-[600] text-left">
                      How would you like to partner with us?
                      </h1>
                 
              </div>


              <div  className="space-y-2 pt-4">
               
                  <div className="flex items-center gap-2">
                  <input
                        type="checkbox"
                        name="preferences"
                        value="option1"
                        data-ff-tab="option1:email:option2"
                      />
                  
                      <span  className="text-[16px] font-[600]">
                        Tools and Services
                      </span>
                    
                  </div>

                  <div className="flex items-center gap-2">
                  
                      <input
                        type="checkbox"
                        name="preferences"
                        class="fd-form-check__input"
                        value="option2"
                        data-ff-tab="option2:option1:option3"
                      />
                 
                      <span  className="text-[16px] font-[600]">
                        Digital Transformation Advocacy
                      </span>
                 
                  </div>

                  <div className="flex items-center gap-2">
                   
                      <input
                        type="checkbox"
                        name="preferences"
                        value="option3"
                        data-ff-tab="option3:option2:cfKPo9EoaG"
                      />
        
                      <span  className="text-[16px] font-[600]">
                        Advisory and Consulting Services
                      </span>
                 
                  </div>

                  <div className="flex items-center gap-2">
                 
                      <input
                        type="checkbox"
                        name="preferences"

                        value="cfKPo9EoaG"
                        data-ff-tab="cfKPo9EoaG:option3:submit"
                      />
                    
                      <span  className="text-[16px] font-[600]">Other Ideas</span>
                  
                  </div>
             
              </div>
            </div>
            <div
              class="ff-642188daedbd2187f135435b__footer"
              data-ff-el="footer"
            >
              <button
                type="submit"
                className="bg-primary hover:bg-[#1361A6] text-white rounded-[4px] py-[10px] px-[30px]"
                data-ff-el="submit"
                data-ff-tab="submit"
              >
                <span>Request</span>
              </button>
            </div>
          </div>
        </form>

      </div>



      <div
        class="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4 dark:border-opacity-50">
        <button
          type="button"
          className="inline-block rounded bg-red-500 px-6 pt-2.5 pb-2 text-xs font-medium uppercase leading-normal text-primary-700 transition duration-150 ease-in-out  focus:outline-none focus:ring-0 "
          data-te-modal-dismiss
          data-te-ripple-init
          data-te-ripple-color="light">
          Close
        </button>
     
      </div>
    </div>
  </div>
</div>
    </>
  );
};

export default PartnerForm;

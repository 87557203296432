import React from 'react'
import {abouts} from "../utils/constants"
//import { Link } from "react-scroll";






const Services = () => {
  return (
   <section id="services" className="py-20 lg:py-32 px-8 md:px-16 bg-[#ffffff] " >
     <div className="w-full text-center space-y-6 pb-10">
      <h1 className="font-bold tracking-wide text-[36px] leading-[40px] md:text-[40px] md:leading-[40px]">
      Want More Than Tools and Technology?
      </h1>
      <p className="m-auto tracking-wide lg:px-24 text-[24px] leading-[32px] md:text-[28px] md:leading-[40px] " >We cover the entire spectrum of financial innovation and digital transformation.</p>
      </div>

    <div className="flex flex-col lg:flex-row items-center gap-10 lg:w-[800px] pb-20  m-auto">
        {abouts.map((about, i) => (
        <div className=" font-light p-6 flex flex-col gap-[24px] items-start text-left bg-white shadow-lg rounded-[24px] " key={i}>
            <img src={about.img} alt={about.title} className="w-14"  />
            <h1 className="font-[600] text-[24px] ">{about.title}</h1>
            <p className="font-[500] text-[18px] leading-[28px] md:text-[18px] md:leading-[32px] ">{about.description}</p>
        </div>
        ))}

    </div>

<div className="w-full flex ">
<div className="m-auto">
            <button data-te-toggle="modal"
  data-te-target="#partner"
  data-te-ripple-init
   className="text-[20px] tracking-wide leading-[19.2px] font-[600] bg-primary hover:bg-[#1361A6] text-white hover:text-white py-4 px-14 rounded-sm justify-center">
Let's talk about it! </button>
          </div>
</div>
    
   </section>
  )
}

export default Services